import Link from 'components/common/Link';
import ResponsiveImage from 'components/common/ResponsiveImage';
import Image from 'components/common/Image';
import useMediaQuery from 'hooks/useMediaQuery';

import { MobileFirstBannerProps } from 'interfaces/cms/content';

function Banner(props) {
  const { image } = props;
  const [isMobile] = useMediaQuery();

  return (
    <>
      <div className="block md:hidden">
        <ResponsiveImage
          src={image.desktopImage}
          srcMobile={image.mobileImage}
          artDirection={image.artDirection}
          width={image.desktopImage?.width}
          height={image.desktopImage?.height}
        />
      </div>
      <div className="hidden md:block overflow-hidden">
        {!isMobile && (
          <Image
            src={image.desktopImage?.url}
            alt={image.desktopImage?.alt || image.desktopImage?.name}
            sizes="100vw"
            width={image.desktopImage?.width}
            height={image.desktopImage?.height}
            priority
          />
        )}
      </div>
    </>
  );
}

function MobileFirstBanner({
  url,
  pageSlug,
  image,
  linkRel,
  backgroundColor,
}: MobileFirstBannerProps) {
  const pageUrl = (pageSlug && `/${pageSlug}`) || url;

  return (
    <div style={{ backgroundColor }} className="flex justify-center">
      <div style={{ width: image.desktopImage?.width }}>
        {pageUrl ? (
          <Link href={pageUrl} rel={linkRel} isExternalLink={!pageSlug}>
            <Banner image={image} />
          </Link>
        ) : (
          <Banner image={image} />
        )}
      </div>
    </div>
  );
}

export default MobileFirstBanner;
